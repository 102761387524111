@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700;800&display=swap');

html, body {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

a {
	color: rgba(176,168,162,1);
	text-decoration: none;
	font-weight: 700;
}

a:hover {
	color: rgba(224,224,218,1);
}

body {
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
	color: rgb(204 203 196);
	background: rgba(17,18,20,1);
}

main {
	padding: 50px;
	display: flex;
	align-items: flex-end;
}

nav {
	padding: 50px;
	display: flex;
	align-items: flex-end;
	justify-items: stretch;
}

.container {
	display: grid;
	grid-template-columns: 1fr;
	height: 100vh;
	width: 100%;
}

.title {
	font-size: 5.25rem;
	font-weight: 700;
  letter-spacing: -5px;
  line-height: 100%;
	text-transform: uppercase;
}

.subtitle {
	font-size: 2rem;
	font-weight: 300;
	text-transform: uppercase;
}

.social {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 10px;
	font-size: 1.5rem;
	color: rgba(176,168,162,1);
	flex-grow: 1;
}

.social a {
	display: grid;
	grid-template-columns: 1fr auto;
	font-weight: 300;
	font-size: 1rem;
	background: rgba(80,120,137,0.1);
	padding: 20px 30px;
	border-radius: 20px;
	align-items: center;
}

.social a .username {
	text-align: right;
}

.name {
	display: grid;
	grid-template-columns: 1fr;
}

@media screen and (min-width: 1320px) {
	.container {
		grid-template-columns: 1fr 500px;
	}
}

@media screen and (min-width: 830px) {
	html, body {
		font-size: 16px;
	}

	main {
		padding: 100px;
	}
	
	nav {
		padding: 100px;
	}
}
